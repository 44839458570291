import { cloneDeep as _cloneDeep } from 'lodash';
import { create } from 'zustand';

import DebugConsole from '@helper/functions/console';
import { AuthState } from '@helper/hooks/useAuth';
import { getTerminalConfig } from '@helper/service/pos-terminal';

const TTL_15_MIN = 15 * 60 * 1000;
const MAX_RETRY = 5;

type CashierTerminalStore = {
  expiry: number;
  retry: number;

  username: string;
  terminalList: TerminalProperties[];
  loading: boolean;

  updateTerminalList: (user: AuthState) => Promise<void>;
  getFullTerminalList: (user: AuthState) => Promise<TerminalProperties[]>;
  getTerminalList: (user: AuthState) => Promise<TerminalProperties[]>;
  getTerminalInfo: (user: AuthState, terminalID: string) => Promise<TerminalProperties>;
  searchTerminalList: (
    user: AuthState,
    searchTerms: string,
    healthEntityCode?: string
  ) => Promise<TerminalProperties[]>;

  // Given a health entity, return a list of terminals
  getTerminalsByHealthEntity: (
    user: AuthState,
    healthEntityCode: string,
  ) => Promise<TerminalProperties[]>;

  // Whether a health entity has at least one terminal
  isHealthEntityWithTerminal: (user: AuthState, healthEntityCode: string) => Promise<boolean>;
};

export const useCashierTerminalStore = create<CashierTerminalStore>()((set, get) => ({
  expiry: 0,
  retry: 0,
  loading: false,

  username: '',
  terminalList: [],

  updateTerminalList: async (user) => {
    if (user.isLoading) return;

    if (get().loading) return;
    if (get().retry > MAX_RETRY) return;
    if (get().username === user.UserName && Date.now() < get().expiry) return;

    try {
      set({ loading: true });
      const data = await getTerminalConfig();

      if (data === undefined) {
        set({
          expiry: Date.now() + 1000,
          retry: get().retry + 1,
          loading: false,
          username: user.UserName,
          terminalList: [],
        });
        return;
      }

      return set({
        expiry: Date.now() + TTL_15_MIN,
        retry: 0,
        loading: false,
        username: user.UserName,
        terminalList: data,
      });
    } catch (err) {
      console.log(err);

      return set({
        loading: false,
        terminalList: [],
      });
    }
  },

  getFullTerminalList: async (user) => {
    if (user.isLoading) return;

    await get().updateTerminalList(user);
    return get().terminalList;
  },

  getTerminalList: async (user) => {
    if (user.isLoading) return;

    const fullList = await get().getFullTerminalList(user);

    // Filter the list based on user settings
    const list = _cloneDeep(fullList);

    return list;
  },

  getTerminalInfo: async (user, terminalID) => {
    if (user.isLoading) return;

    const list = await get().getTerminalList(user);

    const terminal = list.find((x) => x.terminalID === terminalID);

    return terminal;
  },

  searchTerminalList: async (user, searchTerms, healthEntityCode = '') => {
    if (user.isLoading) return;

    DebugConsole.debug(healthEntityCode);
    
    const list = await get().getTerminalList(user);

    if (!searchTerms)
      return list.filter((item) => {
        return !healthEntityCode || healthEntityCode === item.healthEntityCode;
      });

    const terms = searchTerms.toLowerCase();

    return list.filter((item) => {
      if (
        item.healthEntity.toLowerCase().includes(terms) ||
        item.healthEntityCode.toLowerCase().includes(terms) ||
        // item.merchantID.toLowerCase().includes(terms) ||
        item.terminalID.toLowerCase().includes(terms) ||
        item.terminalLocation.toLowerCase().includes(terms) ||
        item.terminalName.toLowerCase().includes(terms)
      ) {
        // If no health entity code provided, return true
        if (!healthEntityCode) return true;

        // If health entity code is supplied, check that it matches
        return healthEntityCode === item.healthEntityCode;
      } else {
        return false;
      }
    });
  },

  getTerminalsByHealthEntity: async (user, healthEntityCode) => {
    if (user.isLoading) return;

    const list = (await get().getTerminalList(user)).filter((item) => {
      if (item.healthEntityCode === healthEntityCode) {
        return true;
      } else {
        return false;
      }
    });

    return list;
  },

  isHealthEntityWithTerminal: async (user, healthEntityCode) => {
    if (user.isLoading) return;

    return (await get().getTerminalList(user)).find(
      (item) => item.healthEntityCode === healthEntityCode,
    )
      ? true
      : false;
  },
}));
